import { fromEventPattern } from 'rxjs';
import { filter, takeUntil, take, share, switchMap } from 'rxjs/operators';
import partition from 'lodash.partition';
import { isAddress, weiHexToEth } from '@web3-onboard/common';
import { disconnectWallet$ } from './streams.js';
import { updateAccount, updateWallet } from './store/actions.js';
import { chainIdToViemENSImport, validEnsChain } from './utils.js';
import disconnect from './disconnect.js';
import { state } from './store/index.js';
import { configuration } from './configuration.js';
import { updateSecondaryTokens } from './update-balances';
import { isHex, toHex } from 'viem';
export const viemProviders = {};
async function getProvider(chain) {
    if (!chain)
        return null;
    if (!viemProviders[chain.rpcUrl]) {
        const viemChain = await chainIdToViemENSImport(chain.id);
        if (!viemChain)
            return null;
        const { createPublicClient, http } = await import('viem');
        const publicProvider = createPublicClient({
            chain: viemChain,
            transport: http()
        });
        viemProviders[chain.rpcUrl] = publicProvider;
    }
    return viemProviders[chain.rpcUrl];
}
export function requestAccounts(provider) {
    const args = { method: 'eth_requestAccounts' };
    return provider.request(args);
}
export function selectAccounts(provider) {
    const args = { method: 'eth_selectAccounts' };
    return provider.request(args);
}
export function getChainId(provider) {
    return provider.request({ method: 'eth_chainId' });
}
export function listenAccountsChanged(args) {
    const { provider, disconnected$ } = args;
    const addHandler = (handler) => {
        provider.on('accountsChanged', handler);
    };
    const removeHandler = (handler) => {
        provider.removeListener('accountsChanged', handler);
    };
    return fromEventPattern(addHandler, removeHandler).pipe(takeUntil(disconnected$));
}
export function listenChainChanged(args) {
    const { provider, disconnected$ } = args;
    const addHandler = (handler) => {
        provider.on('chainChanged', handler);
    };
    const removeHandler = (handler) => {
        provider.removeListener('chainChanged', handler);
    };
    return fromEventPattern(addHandler, removeHandler).pipe(takeUntil(disconnected$));
}
export function trackWallet(provider, label) {
    const disconnected$ = disconnectWallet$.pipe(filter(wallet => wallet === label), take(1));
    const accountsChanged$ = listenAccountsChanged({
        provider,
        disconnected$
    }).pipe(share());
    // when account changed, set it to first account and subscribe to events
    accountsChanged$.subscribe(async ([address]) => {
        // sync accounts with internal state
        // in the case of an account has been manually disconnected
        try {
            await syncWalletConnectedAccounts(label);
        }
        catch (error) {
            console.warn('Web3Onboard: Error whilst trying to sync connected accounts:', error);
        }
        // no address, then no account connected, so disconnect wallet
        // this could happen if user locks wallet,
        // or if disconnects app from wallet
        if (!address) {
            disconnect({ label });
            return;
        }
        const { wallets } = state.get();
        const wallet = wallets.find(wallet => wallet.label === label);
        const accounts = wallet ? wallet.accounts : [];
        const [[existingAccount], restAccounts] = partition(accounts, account => account.address === address);
        // update accounts without ens/uns and balance first
        updateWallet(label, {
            accounts: [
                existingAccount || {
                    address: address,
                    ens: null,
                    uns: null,
                    balance: null
                },
                ...restAccounts
            ]
        });
    });
    // also when accounts change, update Balance and ENS/UNS
    accountsChanged$
        .pipe(switchMap(async ([address]) => {
        if (!address)
            return;
        const { wallets, chains } = state.get();
        const primaryWallet = wallets.find(wallet => wallet.label === label);
        if (!primaryWallet)
            return; // Add null check for primaryWallet
        const { chains: walletChains, accounts } = primaryWallet;
        const [connectedWalletChain] = walletChains;
        const chain = chains.find(({ namespace, id }) => namespace === 'evm' && id === connectedWalletChain.id);
        if (!chain)
            return;
        const balanceProm = getBalance(address, chain);
        const secondaryTokenBal = updateSecondaryTokens(address, chain);
        const account = accounts.find(account => account.address === address);
        const ensChain = chains.find(({ id }) => id === validEnsChain(connectedWalletChain.id));
        const ensProm = account && account.ens
            ? Promise.resolve(account.ens)
            : ensChain
                ? getEns(address, ensChain)
                : Promise.resolve(null);
        const unsProm = account && account.uns
            ? Promise.resolve(account.uns)
            : ensChain
                ? getUns(address, ensChain)
                : Promise.resolve(null);
        return Promise.all([
            Promise.resolve(address),
            balanceProm,
            ensProm,
            unsProm,
            secondaryTokenBal
        ]);
    }))
        .subscribe(res => {
        if (!res)
            return;
        const [address, balance, ens, uns, secondaryTokens] = res;
        updateAccount(label, address, { balance, ens, uns, secondaryTokens });
    });
    const chainChanged$ = listenChainChanged({ provider, disconnected$ }).pipe(share());
    // Update chain on wallet when chainId changed
    chainChanged$.subscribe(async (chainId) => {
        const { wallets } = state.get();
        const wallet = wallets.find(wallet => wallet.label === label);
        if (!wallet)
            return; // Add null check for wallet
        const { chains, accounts } = wallet;
        const [connectedWalletChain] = chains;
        if (!isHex(chainId)) {
            chainId = toHex(chainId);
        }
        if (chainId === connectedWalletChain.id)
            return;
        const resetAccounts = accounts.map(({ address }) => ({
            address,
            ens: null,
            uns: null,
            balance: null
        }));
        updateWallet(label, {
            chains: [{ namespace: 'evm', id: chainId }],
            accounts: resetAccounts
        });
    });
    // when chain changes get ens/uns and balance for each account for wallet
    chainChanged$
        .pipe(switchMap(async (chainId) => {
        const { wallets, chains } = state.get();
        const primaryWallet = wallets.find(wallet => wallet.label === label);
        const accounts = (primaryWallet === null || primaryWallet === void 0 ? void 0 : primaryWallet.accounts) || [];
        if (!isHex(chainId)) {
            chainId = toHex(chainId);
        }
        const chain = chains.find(({ namespace, id }) => namespace === 'evm' && id === chainId);
        if (!chain)
            return Promise.resolve(null);
        return Promise.all(accounts.map(async ({ address }) => {
            const balanceProm = getBalance(address, chain);
            const secondaryTokenBal = updateSecondaryTokens(address, chain);
            const ensChain = chains.find(({ id }) => id === validEnsChain(chainId));
            const ensProm = ensChain
                ? getEns(address, ensChain)
                : Promise.resolve(null);
            const unsProm = ensChain
                ? getUns(address, ensChain)
                : Promise.resolve(null);
            const [balance, ens, uns, secondaryTokens] = await Promise.all([
                balanceProm,
                ensProm,
                unsProm,
                secondaryTokenBal
            ]);
            return {
                address,
                balance,
                ens,
                uns,
                secondaryTokens
            };
        }));
    }))
        .subscribe(updatedAccounts => {
        updatedAccounts && updateWallet(label, { accounts: updatedAccounts });
    });
    disconnected$.subscribe(() => {
        provider.disconnect && provider.disconnect();
    });
}
export async function getEns(address, chain) {
    // chain we don't recognize and don't have a rpcUrl for requests
    if (!chain)
        return null;
    const provider = await getProvider(chain);
    if (!provider)
        return null;
    try {
        const name = await provider.getEnsName({
            address
        });
        let ens = null;
        if (name) {
            const { labelhash, normalize } = await import('viem/ens');
            const normalizedName = normalize(name);
            const ensResolver = await provider.getEnsResolver({
                name: normalizedName
            });
            const avatar = await provider.getEnsAvatar({
                name: normalizedName
            });
            const contentHash = labelhash(normalizedName);
            const getText = async (key) => {
                return await provider.getEnsText({
                    name,
                    key
                });
            };
            ens = {
                name,
                avatar,
                contentHash,
                ensResolver,
                getText
            };
        }
        return ens;
    }
    catch (error) {
        console.error(error);
        return null;
    }
}
export async function getUns(address, chain) {
    const { unstoppableResolution } = configuration;
    // check if address is valid ETH address before attempting to resolve
    // chain we don't recognize and don't have a rpcUrl for requests
    if (!unstoppableResolution || !isAddress(address) || !chain)
        return null;
    try {
        return await unstoppableResolution(address);
    }
    catch (error) {
        console.error(error);
        return null;
    }
}
export async function getBalance(address, chain) {
    // chain we don't recognize and don't have a rpcUrl for requests
    if (!chain)
        return null;
    const { wallets } = state.get();
    try {
        const wallet = wallets.find(wallet => !!wallet.provider);
        if (!wallet)
            return null;
        const provider = wallet.provider;
        const balanceHex = (await provider.request({
            method: 'eth_getBalance',
            params: [address, 'latest']
        }));
        return balanceHex
            ? { [chain.token || 'eth']: weiHexToEth(balanceHex) }
            : null;
    }
    catch (error) {
        console.error(error);
        return null;
    }
}
export function switchChain(provider, chainId) {
    return provider.request({
        method: 'wallet_switchEthereumChain',
        params: [{ chainId }]
    });
}
export function addNewChain(provider, chain) {
    return provider.request({
        method: 'wallet_addEthereumChain',
        params: [
            {
                chainId: chain.id,
                chainName: chain.label,
                nativeCurrency: {
                    name: chain.label,
                    symbol: chain.token,
                    decimals: 18
                },
                rpcUrls: [chain.publicRpcUrl || chain.rpcUrl],
                blockExplorerUrls: chain.blockExplorerUrl
                    ? [chain.blockExplorerUrl]
                    : null
            }
        ]
    });
}
export function updateChainRPC(provider, chain, rpcUrl) {
    return provider.request({
        method: 'wallet_addEthereumChain',
        params: [
            {
                chainId: chain.id,
                chainName: chain.label,
                nativeCurrency: {
                    name: chain.label,
                    symbol: chain.token,
                    decimals: 18
                },
                rpcUrls: [rpcUrl],
                blockExplorerUrls: chain.blockExplorerUrl
                    ? [chain.blockExplorerUrl]
                    : undefined
            }
        ]
    });
}
export async function getPermissions(provider) {
    try {
        const permissions = (await provider.request({
            method: 'wallet_getPermissions'
        }));
        return Array.isArray(permissions) ? permissions : [];
    }
    catch (error) {
        return [];
    }
}
export async function syncWalletConnectedAccounts(label) {
    const wallet = state.get().wallets.find(wallet => wallet.label === label);
    if (!wallet)
        return;
    const permissions = await getPermissions(wallet.provider);
    const accountsPermissions = permissions.find(({ parentCapability }) => parentCapability === 'eth_accounts');
    if (accountsPermissions) {
        const { value: connectedAccounts } = accountsPermissions.caveats.find(({ type }) => type === 'restrictReturnedAccounts') || { value: null };
        if (connectedAccounts) {
            const syncedAccounts = wallet.accounts.filter(({ address }) => connectedAccounts.includes(address));
            updateWallet(wallet.label, Object.assign(Object.assign({}, wallet), { accounts: syncedAccounts }));
        }
    }
}
export const addOrSwitchChain = async (provider, chain) => {
    try {
        const { id } = chain;
        await addNewChain(provider, chain);
        await switchChain(provider, id);
        return id;
    }
    catch (error) {
        return undefined;
    }
};
export const wagmiProviderMethods = () => ({
    addOrSwitchChain,
    getChainId,
    requestAccounts,
    switchChain
});
